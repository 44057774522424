import { render, staticRenderFns } from "./TitleButtons.vue?vue&type=template&id=c5291476&scoped=true&"
import script from "./TitleButtons.vue?vue&type=script&lang=ts&"
export * from "./TitleButtons.vue?vue&type=script&lang=ts&"
import style0 from "./TitleButtons.vue?vue&type=style&index=0&id=c5291476&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5291476",
  null
  
)

export default component.exports