import {
  EssayType,
  OnlineCourse,
  OnlineCoursesWithPagination
} from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

const apiUrl = "/api/online-courses";

export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    name = "",
    limit = 10
  ): Promise<OnlineCoursesWithPagination> {
    try {
      let response = null;
      if (name.length === 0) {
        response = (await $axios.get(`${apiUrl}?page=${page}&limit=${limit}`))
          .data;
      } else {
        response = (
          await $axios.get(
            `${apiUrl}?page=${page}&courses[name]=${name}&limit=${limit}`
          )
        ).data;
      }
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listWithoutPagination(): Promise<any> {
    try {
      const courses = (await $axios.get(`${apiUrl}/get-all-online-courses`))
        .data.data;
      return courses.map((course: any) => toCamel(course));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async savePdf(file: any): Promise<any> {
    try {
      const response = (await $axios.post(`${apiUrl}/upload-manual`, file)).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getPdf(id: number | string): Promise<any> {
    try {
      const response = await $axios.get(`${apiUrl}/${id}/manual`, {
        responseType: "blob"
      });
      return response.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async info(id: number | string): Promise<OnlineCourse> {
    try {
      const response = (await $axios.get(`${apiUrl}/${id}`)).data.data;
      return toCamel(response) as OnlineCourse;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async durations(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-durations`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(apiUrl, data)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(id: string, data: any): Promise<any> {
    try {
      const response = (await $axios.post(`${apiUrl}/${id}`, data)).data.data; //Content-Type: multipart/form-data.
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(courseId: string) {
    try {
      return (await $axios.delete(`${apiUrl}/${courseId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createLearningBlock(data: any): Promise<any> {
    try {
      const response = (await $axios.post("/api/blocks", data)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async storeFormativeTest(courseId: string | number, data: any): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/online-courses/${courseId}/formative`, data)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
    // {
    //       headers: { "Content-Type": "multipart/form-data" }
    //     }
  },
  async updateLearningBlock(id: number | string, data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/blocks/${id}`, data)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteBlockById(id: number): Promise<any> {
    try {
      const response = (await $axios.delete(`/api/blocks/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteFormativeTest(id: number): Promise<any> {
    try {
      const response = (
        await $axios.delete(`/api/online-courses/${id}/formative`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteSummativeTest(id: number): Promise<any> {
    try {
      const response = (
        await $axios.delete(`/api/online-courses/${id}/summative`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getLearningBlocksByCourseId(id: number): Promise<any> {
    try {
      const response = (
        await $axios.get(`/api/blocks?blocks[online_course_id]=${id}`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getLearningBlock(id: number | string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/blocks/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async uploadVideo(data: any): Promise<any> {
    try {
      const response = (await $axios.post("/api/blocks/upload-video", data))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteVideo(id: any): Promise<any> {
    try {
      return await $axios.delete(`/api/blocks/${id}/video`);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteIntroVideo(id: any): Promise<any> {
    try {
      return await $axios.delete(`/api/online-courses/${id}/intro`);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteFinalVideo(id: any): Promise<any> {
    try {
      return await $axios.delete(`/api/online-courses/${id}/outro`);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async categories(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-categories`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async types(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-types`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createSummativeTest(id: number | string, questions: any): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/online-courses/${id}/summative`, questions)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async updateSummativeTest(id: number | string, data: any): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/online-courses/${id}/summative`, {
          ...toSnake(data)
        })
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getFormativeTests(id: number | string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/online-courses/${id}/formative`))
        .data.data;
      return response.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getSummativeTests(id: number | string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/online-courses/${id}/summative`))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getEssayTest(id: number | string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/online-courses/${id}/essay`))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getPracticalTest(id: number | string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/online-courses/${id}/practical`))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createPracticalTest(id: number | string, data: FormData): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/online-courses/${id}/practical`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deletePracticalTest(courseId: string): Promise<any> {
    try {
      const response = (
        await $axios.delete(`/api/online-courses/${courseId}/practical`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createEssayTest(id: number | string, data: EssayType): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/online-courses/${id}/essay`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async updateEssayTest(id: number | string, data: EssayType): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/online-courses/${id}/essay`, {
          ...toSnake(data)
        })
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteEssay(courseId: string): Promise<any> {
    try {
      const response = (
        await $axios.delete(`/api/online-courses/${courseId}/essay`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteFileInQuestion(fileId: string) {
    try {
      const response = (
        await $axios.delete(`/api/questions/${fileId}/delete-file`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
