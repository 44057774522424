import auth from "@/store/modules/auth";
import chats from "@/store/modules/chats";
import company from "@/store/modules/company";
import delegate from "@/store/modules/delegate";
import events from "@/store/modules/events";
import instructors from "@/store/modules/instructors";
import countryCodes from "@/store/modules/service";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

import message from "@/store/modules/message";
import table from "@/store/modules/table";
import Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  // strict: true,
  state: {
    version: "1.0.0"
  },
  modules: {
    auth,
    message,
    table,
    events,
    delegate,
    company,
    instructors,
    chats,
    countryCodes
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365 }),
        removeItem: key => Cookies.remove(key)
      },
      paths: ["auth"]
    })
  ]
};

export default new Vuex.Store<RootState>(store);
