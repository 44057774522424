import { Companies } from "@/api";
import { CompanyState, RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const state: CompanyState = {
  loading: null,
  companyListWithPagination: [],
  companies: []
};

const mutations: MutationTree<CompanyState> = {
  startFetch(state) {
    state.loading = null;
    state.companyListWithPagination = [];
    state.companies = [];
  },
  setListCompanyWithPagination(state, payload) {
    state.companyListWithPagination = payload;
  },
  setCompanyById(state, data) {
    if (!Object.keys(data).length) {
      state.companies = [];
      return;
    }
    state.companies = Object.keys(data).map(index => data[index]);
  }
};
const actions: ActionTree<CompanyState, RootState> = {
  async getListCompanyWithPagination({ commit }, withDeleted: boolean) {
    try {
      const data = await Companies.listWithoutPagination(withDeleted);
      commit("setListCompanyWithPagination", data);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async getCompanyById(
    { commit },
    {
      companyId,
      withDeleted = false
    }: { companyId: string; withDeleted?: boolean }
  ) {
    try {
      const data = await Companies.infoList(companyId, withDeleted);
      commit("setCompanyById", data);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  }
};

const getters: GetterTree<CompanyState, RootState> = {};

const company: Module<CompanyState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default company;
