import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async generateMeeting(eventId: string): Promise<any> {
    try {
      return (await $axios.post(`/api/meetings/chat-meeting/${eventId}`)).data
        .data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
