import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { MessageState, RootState } from "@/store/types";

const state: MessageState = {
  flushError: false,
  flushSuccess: false
};

const namespaced = true;

const actions: ActionTree<MessageState, RootState> = {
  setFlushError({ commit }, flushError): void {
    commit("setFlushError", flushError);
  },
  setFlushSuccess({ commit }, flushSuccess): void {
    commit("setFlushSuccess", flushSuccess);
  }
};

const mutations: MutationTree<MessageState> = {
  setFlushError(state: MessageState, flushError: boolean): void {
    state.flushError = flushError;
  },
  setFlushSuccess(state: MessageState, flushSuccess: boolean): void {
    state.flushSuccess = flushSuccess;
  }
};

const getters: GetterTree<MessageState, RootState> = {
  getFlushError(state: MessageState): boolean {
    return state.flushError;
  },
  getFlushSuccess(state: MessageState): boolean {
    return state.flushSuccess;
  }
};

const message: Module<MessageState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};

export default message;
