import { Delegates } from "@/api";
import { DelegateState, RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const state: DelegateState = {
  loading: null,
  delegatesWithPagination: {},
  delegatesArray: [],
  eventDelegates: []
};

const mutations: MutationTree<DelegateState> = {
  setListDelegates(state, payload) {
    state.delegatesWithPagination = payload;
  },
  setDelegatesForEvent(state, payload) {
    state.eventDelegates = payload;
  }
};
const actions: ActionTree<DelegateState, RootState> = {
  async getListDelegates({ commit }, { page, filters, limit, withDeleted }) {
    if (!filters.company) {
      commit("setListDelegates", {});
      return;
    }
    const data = await Delegates.list(page, filters, limit, withDeleted);
    commit("setListDelegates", data);
    return data.data;
  },
  async getDelegatesForEvent({ commit }, eventId: string) {
    // const data = await Delegates.getDelegatesForEvent(eventId);
    // commit("setDelegatesForEvent", data);
    // return data.data;
  }
};

const getters: GetterTree<DelegateState, RootState> = {};

const delegate: Module<DelegateState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default delegate;
