import { Auth } from "@/api";
import { AuthState, RootState, User } from "@/store/types";
import Cookies from "js-cookie";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
const state: AuthState = {
  accessToken: Cookies.get("access_token") || "",
  isCorrect: true,
  user: null
};

const namespaced = true;

const actions: ActionTree<AuthState, RootState> = {
  async loginRequest(
    { commit },
    { email, password }: { email: string; password: string }
  ) {
    try {
      const res = await Auth.login(email, password);
      commit("setAccessToken", res.access_token);
      commit("setUser", {
        id: res.id,
        email: res.email,
        permissions: res.permissions
      });
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async authLogout({ commit, state }) {
    if (state.user) {
      await Auth.logout(state.user.email);
      Cookies.remove("access_token");
      commit("setAccessToken", "");
      commit("setUser", null);
    }
  }
};

const mutations: MutationTree<AuthState> = {
  setAccessToken(state: AuthState, token: string): void {
    state.accessToken = token;
    Cookies.set("access_token", token, { expires: 365 });
  },
  setUser(state: AuthState, user: User) {
    state.user = user;
  },
  setIsCorrect(state: AuthState, flag: boolean): void {
    state.isCorrect = flag;
  }
};

const getters: GetterTree<AuthState, RootState> = {
  loggedIn(state: AuthState) {
    return !!state.accessToken;
  },
  getIsCorrect(state: AuthState): boolean {
    return state.isCorrect;
  },
  getUser(state: AuthState): object | null {
    return state.user;
  }
};

const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};

export default auth;
