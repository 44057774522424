import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import Default from "@/layouts/default.vue";
// import Auth from "@/layouts/auth.vue";

import VCurrencyField from "@/components/forms/VCurrencyField.vue";
import axios from "axios";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import dotenv from "dotenv";
import moment from "moment";
import { extend } from "vee-validate";
import {
  email,
  max,
  min,
  // eslint-disable-next-line @typescript-eslint/camelcase
  min_value,
  numeric,
  required,
  // eslint-disable-next-line @typescript-eslint/camelcase
  required_if
} from "vee-validate/dist/rules";
import VueApexCharts from "vue-apexcharts";
import VueAxios from "vue-axios";
import VueCurrencyInput from "vue-currency-input";
import VModal from "vue-js-modal";
import "vue-js-modal/dist/styles.css";
import VueSignaturePad from "vue-signature-pad";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.min.css";

import "@/assets/scss/media1024.scss";
import "@/assets/scss/media960.scss";
import "@/assets/scss/style.scss";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const VueCurrencyInputOptions = {
  globalOptions: {
    locale: "en",
    currency: { prefix: "R " },
    autoDecimalMode: true,
    distractionFree: {
      hideNegligibleDecimalDigits: false,
      hideCurrencySymbol: false,
      hideGroupingSymbol: false
    }
  }
};

extend("email", email);
extend("required", required);
extend("required_if", required_if);
extend("required_if_not", {
  // eslint-disable-next-line @typescript-eslint/camelcase
  ...required_if,
  validate: (value, args: any) => {
    return Boolean(args.target || value);
  },
  message: "Field is required"
});
extend("less_than", {
  params: ["target"],
  validate: (value, args: any) => {
    return Boolean(args.target >= value);
  },
  message: `Can't be more than price`
});
extend("min", min);
extend("max", max);
extend("numeric", numeric);
extend("min_value", min_value);
extend("phone", {
  validate(value) {
    // return /^[\+)[\(][0-9]{1,4}[\)][0-9]{7,10}$/g.test(value); //my test
    // eslint-disable-next-line no-useless-escape
    return /[\+][(][0-9]{1,4}[)][0-9]{8,13}$/g.test(value); // last ok
    // return /^[\+][0-9]{2}[0-9]{2}[0-9]{3}[0-9]{4}$/g.test(value); // last ok
    // return /^[\+][0-9]{2}[\s]*[0-9]{2}[0-9]{3}[0-9]{4}$/g.test(value);
    // return /^[+]{0,1}[0-9]{1,3}{0,1}[-\s\./0-9]*$/g.test(value);
  },
  message: "Invalid phone number"
});

extend("password", {
  params: ["target"],
  validate(value: string, { target }: any): boolean {
    return value === target;
  },
  message: "Password confirmation does not match"
});

Vue.use(VueCurrencyInput, VueCurrencyInputOptions);
Vue.use(VueApexCharts);
Vue.use(VModal);
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});
Vue.use(VueSignaturePad);

Vue.component("VCurrencyField", VCurrencyField);
Vue.component("apexchart", VueApexCharts);
// Vue.component("default", Default);
// Vue.component("auth", Auth);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
dotenv.config();
Vue.filter("money", function(value: number | string) {
  const num = parseFloat(value.toString());
  const formattedMoney = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2
  }).format(num);
  return formattedMoney.substr(2);
});
Vue.filter("date", function(value: string) {
  return moment(value).format("DD/MM/YYYY");
});
Vue.prototype.$success = async function(message: any) {
  return await this.$dialog.notify.success(message, {
    position: "bottom-right",
    timeout: 3000
  });
};
Vue.prototype.$error = async function(errors: any, timeout = 5000) {
  const errorsArray = [];
  for (const key in errors) {
    if (typeof errors[key] === "string") {
      errorsArray.push(errors[key]);
    } else {
      errorsArray.push(...errors[key]);
    }
  }
  return await Promise.all(
    errorsArray.map((error: string) =>
      this.$dialog.notify.error(error, {
        position: "bottom-right",
        timeout
      })
    )
  );
};

Vue.prototype.$echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: process.env.VUE_APP_PUSHER_APP_TLS === "true", // we got string from .env file, so we need to convert it to boolean
  encrypted: process.env.VUE_APP_PUSHER_APP_ENCRYPTED === "true",
  wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
  wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
  enabledTransports: ["ws", "wss"],
  authorizer: (channel: any, options: any) => {
    // console.log("channel", channel);
    // console.log("options", options);

    return {
      authorize: (socketId: any, callback: any) => {
        axios
          .post("/broadcasting/auth", {
            socket_id: socketId, // eslint-disable-line @typescript-eslint/camelcase
            channel_name: channel.name // eslint-disable-line @typescript-eslint/camelcase
          })
          .then(response => {
            callback(false, response.data);
          })
          .catch(error => {
            callback(true, error);
          });
      }
    };
  }
});

export const V = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
