import { User, UsersWithPagination } from "@/api/types";
import axios, { AxiosStatic } from "axios";
import toCamelCase from "camelcase-keys";
import { toCamel } from "snake-camel";
function makeQueryParamsFromFilter(
  filters: { name: string; email: string; role: null | number } = {
    name: "",
    email: "",
    role: null
  }
): string {
  const queryParamsArray = [];
  if (filters.name.length > 0) {
    queryParamsArray.push(`users[name]=${filters.name || ""}`);
  }
  if (filters.email.length > 0) {
    queryParamsArray.push(`users[email]=${filters.email || ""}`);
  }
  if (filters.role !== null) {
    queryParamsArray.push(`roles=${filters.role || ""}`);
  }
  return queryParamsArray.join("&");
}
export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    filters: { name: string; email: string; role: null | number } = {
      name: "",
      email: "",
      role: null
    },
    active = 1,
    limit = 10
  ): Promise<UsersWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      let response = null;
      if (queryParams.length > 0) {
        response = (
          await $axios.get(
            `/api/users/?page=${page}&limit=${limit}&active=${active}&${queryParams}`
          )
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/users/?page=${page}&limit=${limit}&active=${active}`
          )
        ).data;
      }
      return toCamelCase(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getCcAgents() {
    try {
      const result = (await $axios.get(`/api/users/`)).data;
      return toCamelCase(result);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getSingleUserRating(id: number | string) {
    try {
      const response = await axios.get(`/api/users/rating/${id}`);
      return toCamel(response.data.data);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async info(userId: string) {
    try {
      return (await $axios.get(`/api/users/${userId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(user: User) {
    try {
      return (await $axios.post("/api/users", user)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(
    userId: string,
    user: { name: string; email: string; roles: Array<number>; active: boolean }
  ) {
    try {
      return (await $axios.put(`/api/users/${userId}`, user)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createGoal(userId: string | number, goals: any) {
    try {
      return (await $axios.put(`/api/users/${userId}`, goals)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(userId: string) {
    try {
      return (await $axios.delete(`/api/users/${userId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async activate(userId: number) {
    try {
      return (await $axios.post(`/api/users/${userId}/activate`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deactivate(userId: number) {
    try {
      return (await $axios.post(`/api/users/${userId}/deactivate`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
