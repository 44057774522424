import { Audit, AuditsWithPagination } from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    name = "",
    limit = 10
  ): Promise<AuditsWithPagination> {
    try {
      let response = null;
      if (name.length === 0) {
        response = (
          await $axios.get(`/api/audits/?page=${page}&limit=${limit}`)
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/audits/?page=${page}&audits[name]=${name}&limit=${limit}`
          )
        ).data;
      }
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async info(id: string): Promise<Audit> {
    try {
      const response = (await $axios.get(`/api/audits/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async durations(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-durations`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async listWithoutPagination(): Promise<any> {
    try {
      const audits = (await $axios.get(`/api/audits/get-all-audits`)).data.data;
      return audits.map((audit: any) => toCamel(audit));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/audits`, { ...toSnake(data) }))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async update(id: string, data: any): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/audits/${id}`, { ...toSnake(data) })
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async remove(courseId: string) {
    try {
      return (await $axios.delete(`/api/audits/${courseId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
