import { PhoneCodesListItem } from "@/store/types";
import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";
import { ServiceList } from "./types";

export default ($axios: AxiosStatic) => ({
  async list(): Promise<ServiceList> {
    try {
      const res: any = toCamel(
        (await $axios.get(`/api/services-list`)).data.data
      );
      return toCamel(res) as ServiceList;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async countryCodesList(): Promise<PhoneCodesListItem[]> {
    try {
      const data = (await $axios.get(`/api/country-list`)).data;
      const formattedData: PhoneCodesListItem[] = Object.entries(data).map(
        ([country, value]: any) => {
          return {
            dialCode: value.code,
            iso2: country,
            label: value.name
          };
        }
      );
      return formattedData;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
