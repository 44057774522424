import { Audits, Courses, Events, OnlineCourses } from "@/api";
import { EventsState, RootState } from "@/store/types";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const state: EventsState = {
  courses: [],
  audits: [],
  onlineCourses: [],
  officeTypes: [],
  event: {
    eventType: { id: 0, name: "", eventableType: "" },
    // eventTypeId: 1,
    instructorId: null,
    eventableType: null,
    eventableId: 0,
    // courseId: null,
    // auditId: null,
    hourFrom: "08:00",
    hourTo: "16:30",
    officeTypeId: 1,
    eventStartDate: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    address: "",
    transportPrice: "",
    accommodation: "",
    duration: undefined,
    price: undefined,
    company: [],
    offlineTrainings: []
  },
  durations: [],
  coursesInfo: null,
  loading: null,
  courseOverview: [],
  OfficeAddress: ""
};

const mutations: MutationTree<EventsState> = {
  startFetch(state) {
    state.courses = [];
    state.audits = [];
    state.onlineCourses = [];
    state.officeTypes = [];
    state.event = {
      eventableType: null,
      eventableId: 0,
      instructorId: null,
      // eventTypeId: 1,
      eventType: { id: 0, name: "", eventableType: "" },
      hourFrom: "08:00",
      hourTo: "16:30",
      officeTypeId: 1,
      eventStartDate: moment()
        .add(1, "days")
        .format("YYYY-MM-DD"),
      address: "",
      transportPrice: "",
      accommodation: "",
      duration: undefined,
      price: undefined,
      company: []
    };
    state.durations = [];
    state.coursesInfo = null;
    state.loading = null;
    state.courseOverview = [];
    state.OfficeAddress = "";
  },
  setCoursesList(state, payload): void {
    state.courses = payload;
  },
  setAuditsList(state, payload): void {
    state.audits = payload;
  },
  setOnlineCoursesList(state, payload): void {
    state.onlineCourses = payload;
  },

  setOfficeTypes(state, payload): void {
    state.officeTypes = payload;
  },
  setEventById(state, payload): void {
    state.event = payload;
    state.event.company = payload.company || [];
    state.event.price = 0;
  },
  setCoursesDurations(state, payload): void {
    state.durations = payload;
  },
  setCoursesInfo(state, payload): void {
    state.coursesInfo = payload;
  },

  setNewCompany(state, payload) {
    state.event.company?.push(payload);
  },
  updCompany(state, { index, data }) {
    if (state.event.company?.length) {
      state.event.company[index] = data;
    }
  },
  updCompanies(state, data) {
    state.event.company = data;
  },
  removeCompany(state, index) {
    state.event.company?.splice(index, 1);
  },
  setOfficeAddress(state, adress) {
    state.OfficeAddress = adress;
  },
  updEvent(state, event) {
    state.event = event;
  },
  setEventType(state, type) {
    state.event.eventType = type;
  },
  setEventEventableId(state) {
    state.event.eventableId = 0;
  },
  setOfflineTrainingRegister(state, items) {
    state.event.offlineTrainings = [...items];
  }
};
const actions: ActionTree<EventsState, RootState> = {
  async getCoursesList({ commit }) {
    try {
      const data = await Courses.listWithoutPagination();
      commit("setCoursesList", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getAuditsList({ commit }) {
    try {
      const data = await Audits.listWithoutPagination();
      commit("setAuditsList", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.message;
    }
  },
  async getOnlineCoursesList({ commit }) {
    try {
      const data = await OnlineCourses.listWithoutPagination();
      commit("setOnlineCoursesList", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.message;
    }
  },
  async getOfficeTypes({ commit }) {
    try {
      const data = await Events.officeTypes();

      const typesWithoutOnline: any[] = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].name !== "Online") typesWithoutOnline.push(data[i]);
      }
      // data.reduce((acc: any[], cur: any) => {
      //   console.log("cc: ", cur, acc);

      //   return cur.name !== "Online" ? acc.push(cur) : acc;
      // }, []);
      commit("setOfficeTypes", typesWithoutOnline);
      return typesWithoutOnline;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getEventById({ commit }, eventId: string) {
    try {
      const data = await Events.info(eventId);
      commit("setEventById", { ...data });
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getCoursesDurations({ commit }) {
    try {
      const data = await Courses.durations();
      commit("setCoursesDurations", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },

  async getCoursesInfo({ commit }, id) {
    try {
      const data = await Courses.info(id);
      commit("setCoursesInfo", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getAuditInfo({ commit }, id) {
    try {
      const data = await Audits.info(id);

      commit("setCoursesInfo", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.message;
    }
  },
  async getOnlineCoursesInfo({ commit }, id) {
    try {
      const data = await OnlineCourses.info(id);
      commit("setCoursesInfo", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async createEvent(store, { eventData, selectedDelegates }) {
    try {
      if (
        !eventData.company[0].price ||
        eventData.company[0].companyId === "undefined"
      ) {
        eventData.company = [];
      } else {
        for (let i = 0; i < eventData.company.length; i++) {
          if (
            !eventData.company[i].price ||
            !eventData.company[i].companyId ||
            eventData.company[i].companyId === "undefined"
          ) {
            throw { errors: { error: "Company or price empty" } };
          }
        }
      }

      return await Events.create(eventData, selectedDelegates);
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async removeEvent(store, eventId) {
    try {
      await Events.remove(eventId);
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },

  async updEvent(store, { eventData, selectedDelegates }) {
    try {
      if (
        !eventData.company[0].price ||
        eventData.company[0].companyId === "undefined"
      ) {
        eventData.company = [];
      } else {
        for (let i = 0; i < eventData.company.length; i++) {
          if (
            !eventData.company[i].price ||
            !eventData.company[i].companyId ||
            eventData.company[i].companyId === "undefined"
          ) {
            throw { errors: { error: "Company or price empty" } };
          }
        }
      }

      return await Events.update(eventData, selectedDelegates);
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async removeCompanies({ commit }, { eventId, delegates, id, index }) {
    try {
      if (id) {
        const params = {
          eventId: eventId,
          delegates: delegates,
          companyPriceId: id
        };
        await Events.removeCompany(params);
      }
      commit("removeCompany", index);
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getOfficeAddress() {
    const data = await Events.getOfficeAddress();
    return data;
  },
  async getEventTypes() {
    try {
      const data = await Events.getEventTypes();
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  }
};

const getters: GetterTree<EventsState, RootState> = {};

const event: Module<EventsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default event;
