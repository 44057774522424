import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

const quoteFilters = (filters: any): string => {
  filters = toSnake(filters);
  const filtersList = Object.keys(filters);

  let params: any = "";
  filtersList.forEach(filter => {
    const defaultCondition =
      filters[filter] !== null &&
      filters[filter] !== undefined &&
      filters[filter] !== "";
    if (defaultCondition && filter === "name") {
      if (isNaN(filters[filter])) {
        params += `&companies[representative_name]=${filters[filter]}`;
      } else {
        params += `&quotes[id]=${filters[filter]}`;
      }
    } else if (defaultCondition && filter === "status_id") {
      params += `&quotes[status]=${filters[filter]}`;
    } else if (defaultCondition && filter === "company_name") {
      params += `&companies[name]=${filters[filter]}`;
    } else if (defaultCondition && filter === "cc_agent") {
      params += `&user[name]=${filters[filter]}`;
    } else if (defaultCondition && filter === "date_to") {
      params += `&date[end_date]=${filters[filter]}`;
    } else if (defaultCondition && filter === "date_from") {
      params += `&date[start_date]=${filters[filter]}`;
    }
  });
  return params;
};
export default ($axios: AxiosStatic) => ({
  async loadQuotesData(page: number, filters: any = {}) {
    try {
      const params = quoteFilters(filters);
      const response = await (
        await $axios.get(`api/quotes/?page=${page}${params}`)
      ).data;

      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async fetchQuoteById(quoteId: number): Promise<any> {
    try {
      const response = (await $axios.get(`api/quotes/${quoteId}/info`)).data
        .data;

      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async accept(quoteId: number) {
    try {
      await $axios.put(`/api/quotes/manage/${quoteId}`, {
        id: quoteId,
        status: 2
      });
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async decline(quoteId: number) {
    try {
      await $axios.put(`/api/quotes/manage/${quoteId}`, {
        id: quoteId,
        status: 3
      });
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async resendEmail(quoteId: number) {
    try {
      await $axios.get(`/api/quotes/sent/${quoteId}`);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async getSingleQuote(quoteId: number) {
    try {
      const response = await $axios(`/api/quotes/preview/${quoteId}`, {
        method: "GET",
        responseType: "blob"
      });
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async exportQuotes(filters: any) {
    try {
      const queryParams = quoteFilters(filters);
      return (
        await $axios.request({
          url: `/api/quotes/export?${queryParams}`,
          method: "GET",
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  }
});
