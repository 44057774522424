
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class TitleButtons extends Vue {
  @Prop({ default: "" })
  activeClass!: string;

  @Prop({ default: "" })
  icon!: string;

  @Prop({ default: "" })
  text!: string;

  @Prop({ default: "white" })
  color!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop(String)
  action!: string;

  onClick() {
    this.$emit(this.action);
    this.$emit("btnClick", this.action);
  }
}
