
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  }
})
export default class VCurrencyField extends Vue {
  @Prop({ required: true, default: null }) value!: number;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false }) errors!: any;
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: "" }) placeholder!: string;

  $refs!: {
    input: HTMLInputElement;
  };

  formattedValue = null;
  @Watch("value")
  updValue(value: any) {
    this.setValue(value);
  }
  mounted() {
    this.setValue(this.value);
  }
  setValue(value: any) {
    this.$ci.setValue(this.$refs.input, value);
  }
  onInput(value: any) {
    this.$emit("input", this.$ci.getValue(this.$refs.input));
    this.formattedValue = value;
  }
  onChange(value: any) {
    this.$emit("change", this.$ci.getValue(this.$refs.input));
    this.formattedValue = value;
  }
}
