import { DelegatesWithPagination } from "@/api/types";
import { AxiosStatic } from "axios";
import moment from "moment";
import { toCamel, toSnake } from "snake-camel";

function makeQueryParamsFromFilter(
  filters: { name: string; date: string } = { name: "", date: "" }
): string {
  const queryParamsArray = [];
  if (filters.name.length > 0) {
    queryParamsArray.push(`courses[name]=${filters.name}`);
  }
  if (filters.date.length > 0) {
    queryParamsArray.push(
      `events[event_start_date]=${moment(filters.date).format("YYYY-MM-DD")}`
    );
  }
  return queryParamsArray.join("&");
}
export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    filters: {
      company: number | null;
      name: string;
      id: string | number;
      active?: string | number;
    } = {
      company: null,
      name: "",
      id: "",
      active: 1
    },
    limit = 10,
    withDeleted = false
  ): Promise<DelegatesWithPagination> {
    try {
      let paramsStr = "";
      if (filters.company !== null && filters.name !== "") {
        paramsStr = `delegates[company_id]=${filters.company}&delegates[name]=${filters.name}`;
      } else if (filters.company !== null) {
        paramsStr = `delegates[company_id]=${filters.company}`;
      } else if (filters.id !== "") {
        paramsStr = `delegates[passport]=${filters.id}`;
      } else if (filters.name !== "") {
        paramsStr = `delegates[name]=${filters.name}`;
      }

      const trashed = `${withDeleted ? "&scopes[0]=withTrashed" : ""}`;

      const active = filters.active || "1";
      if (Object.keys(filters).length === 0) {
        return (
          await $axios.get(
            `/api/delegates?page=${page}&limit=${limit}&active=${active}${trashed}`
          )
        ).data;
      } else {
        return (
          await $axios.get(
            `/api/delegates?page=${page}&limit=${limit}&active=${active}&${paramsStr}${trashed}`
          )
        ).data;
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listWithoutPagination(): Promise<any> {
    try {
      const response = (await $axios.get(`/api/delegates/all`)).data.data;
      return response.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async info(id: string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/delegates/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async events(
    delegateId: string | number,
    page: number,
    filters: { name: string; date: string } = { name: "", date: "" }
  ): Promise<any> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      if (queryParams.length > 0) {
        return (
          await $axios.get(
            `/api/delegates/${delegateId}/events?page=${page}&${queryParams}`
          )
        ).data;
      } else {
        return (
          await $axios.get(`/api/delegates/${delegateId}/events?page=${page}`)
        ).data;
      }
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async genders() {
    try {
      return (await $axios.get(`/api/services-list`)).data.data.genders;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async titles() {
    try {
      const res = (await $axios.get(`/api/services-list`)).data.data;
      if (res.titles) {
        return res.titles;
      } else {
        return [
          { id: 1, name: "Mr" },
          { id: 2, name: "Miss" },
          { id: 3, name: "Mrs" },
          { id: 4, name: "Dr" }
        ];
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async delegatesForEvent(
    eventId: string | number
  ): Promise<
    Array<{
      id: number;
      name: string;
      surname: string;
      companyId: number;
    }>
  > {
    try {
      const delegates = (
        await $axios.get(`/api/delegates/all?events[id]=${eventId}`)
      ).data.data;
      return delegates.map((item: any) => toCamel(item));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/delegates`, toSnake(data))).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(id: string, data: any): Promise<any> {
    try {
      const response = (await $axios.put(`/api/delegates/${id}`, toSnake(data)))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(id: string) {
    try {
      return (await $axios.delete(`/api/delegates/${id}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async changePassword(
    delegateId: string,
    password: string,
    confirmPassword: string
  ) {
    try {
      return (
        await $axios.post(`/api/delegates/${delegateId}/change-password`, {
          password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: confirmPassword
        })
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getDelegateResults(
    eventId: string | number,
    delegateId: string | number
  ): Promise<any> {
    try {
      return (
        await $axios.get(`/api/events/${eventId}/get-result/${delegateId}`)
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data.message || err.response.data.result);
    }
  },
  async getDocumentsTypes(): Promise<any> {
    try {
      return (await $axios.get(`/api/delegate-documents/type`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data.message || err.response.data.result);
    }
  },
  async uploadDelegateDocuments(
    delegateId: string,
    document: Blob,
    typeId: number
  ): Promise<any> {
    const data = new FormData();
    data.append("document", document);
    data.append("type_id", String(typeId));
    try {
      return (
        await $axios.post(`/api/delegate-documents/${delegateId}/upload`, data)
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(
        err.response.statusText
          ? { err: err.response.statusText }
          : err.response.data.result
      );
    }
  },
  async getDelegateDocuments(delegateId: string): Promise<any> {
    try {
      const res = (await $axios.get(`/api/delegate-documents/${delegateId}`))
        .data.data;

      return res.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data.message || err.response.data.result);
    }
  },
  async getVaultEmailStatus(delegateId: string | number): Promise<any> {
    try {
      const response = (
        await $axios.get(`api/delegates/${delegateId}/vault-email-status`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async resendVaultVerificationEmail(delegateId: string): Promise<any> {
    try {
      const response = (
        await $axios.post(
          `api/delegates/${delegateId}/vault-resend-verification`
        )
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  }
});
