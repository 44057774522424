import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { TableState, RootState } from "@/store/types";

const state: TableState = {
  currentItem: {},
  currentItems: []
};

const namespaced = true;

const actions: ActionTree<TableState, RootState> = {
  selectItem({ commit }, item: any): void {
    commit("setCurrentItem", item);
  },
  refreshItem({ commit }): void {
    commit("refreshItem");
  },
  multiSelectItems({ commit }, item): void {
    commit("setCurrentItems", item);
  },
  deleteItemFromArray({ commit }, item: object): void {
    commit("deleteFromArray", item);
  },
  refreshArrayOfItems({ commit }): void {
    commit("refreshArrayOfItems");
  }
};

const mutations: MutationTree<TableState> = {
  setCurrentItem(state: TableState, data: any): void {
    state.currentItem = data;
  },
  refreshItem(state: TableState): void {
    state.currentItem = {};
  },
  setCurrentItems(state: TableState, data: any): void {
    state.currentItems.push(data);
  },
  deleteFromArray(state: TableState, data: any): void {
    const matchItemIndex = state.currentItems.findIndex(
      (item: any) => item.id === data.id
    );
    state.currentItems.splice(matchItemIndex, 1);
  },
  refreshArrayOfItems(state: TableState): void {
    state.currentItems = [];
  }
};

const getters: GetterTree<TableState, RootState> = {
  getCurrentItem(state: TableState): any {
    return state.currentItem;
  },
  getArrayOfItems(state: TableState): any {
    return state.currentItems;
  }
};

const table: Module<TableState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};

export default table;
