import { Course, CoursesWithPagination } from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    name = "",
    limit = 10
  ): Promise<CoursesWithPagination> {
    try {
      let response = null;
      if (name.length === 0) {
        response = (
          await $axios.get(`/api/courses/?page=${page}&limit=${limit}`)
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/courses/?page=${page}&courses[name]=${name}&limit=${limit}`
          )
        ).data;
      }
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async info(id: string): Promise<Course> {
    try {
      const response = (await $axios.get(`/api/courses/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async durations(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-durations`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async categories(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-categories`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async types(): Promise<any> {
    try {
      return (await $axios.get(`/api/course-types`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listWithoutPagination(): Promise<any> {
    try {
      const courses = (await $axios.get(`/api/courses/get-all-courses`)).data
        .data;
      return courses.map((course: any) => toCamel(course));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/courses`, { ...toSnake(data) }))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(id: string, data: any): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/courses/${id}`, { ...toSnake(data) })
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(courseId: string) {
    try {
      return (await $axios.delete(`/api/courses/${courseId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
