import { Chat, Meetings } from "@/api";
import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  ChatList,
  ChatListType,
  ChatState,
  MessagesMetaType
} from "../chat.types";
import store from "../index";

const state: ChatState = {
  activeTab: 1,
  currentlyOpenChatId: null,
  chats: {} as ChatList,
  messages: [],
  messagesMeta: {} as MessagesMetaType,
  privateChats: [],
  groupChats: [],
  groupChatUsers: [],
  loading: false,
  page: 1,
  limit: 20,
  timeStamp: 1
};

const mutations: MutationTree<ChatState> = {
  setPage(state, val) {
    state.page = val;
  },
  setLimit(state, val) {
    state.limit = val;
  },
  setTimeStamp(state) {
    state.timeStamp += 1;
    if (state.timeStamp > 1000) state.timeStamp = 1;
  },
  setLoading(state, val) {
    state.loading = val;
  },
  setActiveTab(state, val) {
    state.activeTab = val;
  },
  setCurrentlyOpenChatId(state, id) {
    state.currentlyOpenChatId = id;
  },
  setChats(state, chats) {
    state.chats = chats;
    state.privateChats = [...chats.private_chat];
    state.groupChats = [...chats.group_chat];
  },
  setMessages(state, messages) {
    if (state.page > 1) {
      state.messages = [...messages, ...state.messages];
    } else {
      state.messages = messages || [];
    }
  },
  setMessagesMeta(state, meta) {
    state.messagesMeta = meta;
  },
  addMessage(state, message) {
    state.messages.push(message);
  },
  updatePrivateChatsList(state, list) {
    state.privateChats = [...list];
  },
  updateGroupChatsList(state, list) {
    state.groupChats = [...list];
  },
  resetChat(state) {
    state.currentlyOpenChatId = null;
    state.messages = [];
    state.page = 1;
    state.groupChatUsers = [];
  },
  setGroupChatUsers(state, { group }) {
    // state.groupChatUsers = users;
    const { users } = group;
    const instructor = { id: users.id, name: users.name };
    delete users.id;
    delete users.name;

    const res = Object.values(users);

    res.push(instructor);

    state.groupChatUsers = [...res] as any;
  },
  setMessagesSeen(
    state,
    // eslint-disable-next-line @typescript-eslint/camelcase
    { messageIds, userId }: { messageIds: number[]; userId: number }
  ) {
    const messages = JSON.parse(JSON.stringify(state.messages));
    // const messages = state.messages;

    messageIds.forEach(idToUpdate => {
      const index = messages.findIndex(
        (message: any) => message.id == idToUpdate
      );
      // if (message) {
      const message = JSON.parse(JSON.stringify(messages[index]));
      if (message.users_read) {
        message.users_read.push(userId);
      } else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        message["users_read"] = [userId];
        // eslint-disable-next-line @typescript-eslint/camelcase
        // message = Object.assign({}, message, { users_read: [userId] });
        // this.$set(message, "users_read");
      }
      messages[index] = { ...message };
      state.messages = [...messages];
      // }
    });
  }
};
const actions: ActionTree<ChatState, RootState> = {
  async getChatsList({ commit, dispatch }) {
    try {
      const chats = await Chat.getChats();

      commit("setChats", chats);

      return chats;
    } catch (e) {
      // console.log("error getting chats: ", e);
    }
  },
  async getMessagesByCurrentChatId({ commit, dispatch, state }, id: string) {
    commit("setMessages", []);

    try {
      commit("setLoading", true);
      const data = await Chat.getMessagesByChatId(id, state.page, state.limit);
      const messages = data.data
        .map((message: any) => message.message)
        .reverse();

      commit("setMessages", messages);
      commit("setMessagesMeta", data.meta);

      const userId = store.getters["auth/getUser"].id;

      const messageIds = messages
        .filter((message: any) => message.user_id != userId)
        .map((message: any) => message.id);
      dispatch("makeMessagesSeen", { chatId: id, messageIds });
    } catch (e) {
      commit("setPage", state.page - 1);
    } finally {
      commit("setLoading", false);
    }
    // const messages = messagesMock[id - 1];
    // console.log("id: ", id);
  },
  async sendMessage({ state }, message) {
    try {
      const chatId = state.currentlyOpenChatId;
      if (chatId) {
        await Chat.sendMessage(chatId, message);
      }
    } catch (e) {
      // console.log("send err: ", e);
    }
  },
  updatePrivateChannelsList({ commit, state }, { message, chatId }) {
    const index = state.privateChats.findIndex(ch => ch.chat_id === chatId);
    const chat = state.privateChats.splice(index, 1)[0];
    chat.lastMessage = { body: message.text };
    const chatList = [chat, ...state.privateChats];

    commit("updatePrivateChatsList", chatList);
  },
  updateGroupChannelsList({ commit, state }, { message, chatId }) {
    const index = state.groupChats.findIndex(ch => ch.chat_id === chatId);
    const chat = { ...state.groupChats.splice(index, 1)[0] };
    chat.lastMessage = { body: message.text };
    const chatList = [chat, ...state.groupChats];

    commit("updateGroupChatsList", chatList);
  },
  async generateZoomMeeting({ dispatch }, eventId: string) {
    try {
      const meeting = await Meetings.generateMeeting(eventId);
      const message = {
        message: `Your link to zoom: ${meeting.join_url}`,
        attachment: null
      };
      dispatch("sendMessage", message);
      return meeting;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async makeMessagesSeen(
    { commit },
    { chatId, messageIds }: { chatId: string; messageIds: number[] }
  ) {
    if (messageIds.length) {
      try {
        await Chat.readMessages(chatId, messageIds);
      } catch (e) {
        // console.log("send err: ", e);
      }
    }
  }
};

const getters: GetterTree<ChatState, RootState> = {
  isLoading(state): boolean {
    return state.loading;
  },
  getChats(state): ChatList {
    return state.chats;
  },
  getPrivateChats(state): ChatListType[] {
    return state.chats.private_chat;
  },
  getGroupChats(state): ChatListType[] {
    return state.chats.group_chat;
  },
  getActiveTab(state): number {
    return state.activeTab;
  }
};

const chats: Module<ChatState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default chats;
