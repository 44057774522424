import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async createCompanyInfo(data: any) {
    try {
      return await $axios.post(`/api/office_address`, data);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async submitCompanyInfo(data: any, id: number | string | null) {
    try {
      return await $axios.put(`/api/office_address/${id}`, data);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  }
});
