
import { Events } from "@/api";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

const namespace = "auth";

@Component
export default class TooltipTemplate extends Vue {
  @Prop({ required: true, default: () => ({}) })
  scheduler!: object;

  @Prop({ required: true, default: () => ({}) })
  templateTooltipModel!: any;

  @Getter("getUser", { namespace })
  getUser!: object | null;

  get canDeleteEvent() {
    const currentUser: any = this.getUser;
    const permissionSearch = currentUser.permissions.find(
      (userPermission: string) => userPermission === "delete_event"
    );
    return Boolean(permissionSearch);
  }

  get event() {
    return this.templateTooltipModel.appointmentData;
  }
  get eventStartTime() {
    const eventDate = new Date(this.event.startDate);
    return moment(eventDate).format("HH:mm");
  }
  get eventEndTime() {
    const eventDate = new Date(this.event.endDate);
    return moment(eventDate).format("HH:mm");
  }
  get blockDeletion() {
    return this.event?.block_deletion || false;
  }
  async onEdit() {
    await this.$router.push({
      name: "EditEvent",
      params: { id: this.event.id.toString() }
    });
  }
  async onGrade() {
    await this.$router.push({
      name: "ViewEvent",
      params: { id: this.event.id.toString() }
    });
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      await Events.remove(this.event.id);
      this.$emit("delete", this.event.text);
    }
  }
}
