import { ChatList } from "@/store/chat.types";
import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async sendTestMessage(message: string): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/send-test-message`, { message })
      ).data;
      return response;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async sendMessage(chatId: string, message: any): Promise<any> {
    try {
      const data = new FormData();
      data.append("message", message.message);
      data.append("attachment", message.attachment);

      const response = (
        await $axios.post(`/api/chats/${chatId}/messages`, data)
      ).data;

      return response;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async readMessages(chatId: string, messageIds: number[]): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/chats/${chatId}/read-messages`, { messageIds })
      ).data;

      return response;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getChats(): Promise<ChatList> {
    try {
      return (await $axios.get("/api/chats")).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getMessagesByChatId(
    chatId: string,
    page: number,
    limit: number
  ): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/chats/${chatId}/messages?page=${page}&length=${limit}`
        )
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
