import {
  CompaniesWithPagination,
  Company,
  CompanyRepresentativesWithPagination,
  DelegatesWithPagination
} from "@/api/types";
import { AxiosStatic } from "axios";
import moment from "moment";
import { toCamel, toSnake } from "snake-camel";

function makeQueryParamsFromFilter(
  filters: { course: string; date: string } = { course: "", date: "" }
): string {
  const queryParamsArray = [];
  if (filters.course.length > 0) {
    queryParamsArray.push(`courses[name]=${filters.course}`);
  }
  if (filters.date.length > 0) {
    queryParamsArray.push(
      `events[event_start_date]=${moment(filters.date).format("YYYY-MM-DD")}`
    );
  }
  return queryParamsArray.join("&");
}
export default ($axios: AxiosStatic) => ({
  async list(page: number, name = ""): Promise<CompaniesWithPagination> {
    try {
      let response = null;
      if (name.length === 0) {
        response = (await $axios.get(`/api/companies/?page=${page}`)).data;
      } else {
        response = (
          await $axios.get(
            `/api/companies/?page=${page}&companies[name]=${name}`
          )
        ).data;
      }

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listWithoutPagination(withDeleted?: boolean): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/companies/all${withDeleted ? "?scopes[0]=withTrashed" : ""}`
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async loadCcAgents(): Promise<any> {
    try {
      return (await $axios.get(`/api/users?roles=2&limit=40`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async delegates(
    companyId: number | string,
    page = 1,
    name = "",
    type: string | number = ""
  ): Promise<DelegatesWithPagination> {
    try {
      if (name.length > 0) {
        return (
          await $axios.get(
            `/api/companies/${companyId}/delegates?page=${page}&delegates[name]=${name}&companies[type]=${type}`
          )
        ).data;
      } else {
        return (
          await $axios.get(
            `/api/companies/${companyId}/delegates?page=${page}&companies[type]=${type}`
          )
        ).data;
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async representatives(
    companyId: number | string,
    page = 1,
    name = "",
    type: string | number = ""
  ): Promise<CompanyRepresentativesWithPagination> {
    try {
      if (name.length > 0) {
        return (
          await $axios.get(
            `/api/companies/${companyId}/delegates?page=${page}&delegates[name]=${name}&companies[type]=${type}`
          )
        ).data;
      } else {
        return (
          await $axios.get(
            `/api/companies/${companyId}/delegates?page=${page}&companies[type]=${type}`
          )
        ).data;
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async bookings(
    companyId: number | string,
    page = 1,
    filters: { course: string; date: string } = { course: "", date: "" }
  ): Promise<any> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      if (queryParams.length > 0) {
        return (
          await $axios.get(
            `/api/companies/${companyId}/bookings?page=${page}&${queryParams}`
          )
        ).data;
      } else {
        return (
          await $axios.get(`/api/companies/${companyId}/bookings?page=${page}`)
        ).data;
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async contractTypes(): Promise<any> {
    try {
      return (await $axios.get(`/api/company-contract-types`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async info(id: string | number | undefined): Promise<Company> {
    try {
      const response = (await $axios.get(`/api/companies/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async infoList(companiesId: string, withDeleted: boolean): Promise<any> {
    // if (!companiesId) return;
    const query = `companies[id]=${companiesId}${
      withDeleted ? "&scopes[0]=withTrashed" : ""
    }`;

    try {
      const response = (await $axios.get(`api/companies?${query}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async infoPrivate(id: string | number | undefined): Promise<Company> {
    try {
      const response = (await $axios.get(`/api/companies/private/${id}`)).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/companies`, toSnake(data))).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createPrivate(data: any): Promise<any> {
    try {
      const response = (
        await $axios.post(`api/companies/private`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(id: string | number, data: any): Promise<any> {
    try {
      const response = (await $axios.put(`/api/companies/${id}`, toSnake(data)))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async updatePrivate(id: string | number, data: any): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/companies/private/${id}`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(courseId: string) {
    try {
      return (await $axios.delete(`/api/companies/${courseId}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createQuote(data: any) {
    try {
      const response = (await $axios.post(`/api/quotes`, toSnake(data))).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async updateQuote(data: any, quoteId: number) {
    try {
      const response = (
        await $axios.put(`/api/quotes/${quoteId}`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async previewQuote(data: any) {
    try {
      const response = await $axios(`api/quotes/preview`, {
        data: toSnake(data),
        method: "POST",
        responseType: "blob"
      });
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async getListCompanyByType(
    type: number,
    filters: { page: number; companyName: string }
  ) {
    try {
      return toCamel(
        (
          await $axios.get(`/api/companies`, {
            params: {
              page: filters.page,
              "companies[type]": type,
              "companies[name]": filters.companyName
            }
          })
        ).data
      );
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async changePassword(
    crId: string,
    password: string,
    confirmPassword: string
  ) {
    try {
      return (
        await $axios.post(
          `/api/company-representatives/${crId}/change-password`,
          {
            password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            password_confirmation: confirmPassword
          }
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async assignCR(companyId: string, crId: string): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/companies/${companyId}/assign-cr/${crId}`)
      ).data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async unAssignCR(companyId: string, crId: string): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/companies/${companyId}/unassign-cr/${crId}`)
      ).data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
