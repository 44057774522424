import { Services } from "@/api";
import { PhoneCodesListItem, RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

type CountryCodesState = {
  countryCodes: PhoneCodesListItem[];
};

const namespaced = true;

const state: CountryCodesState = {
  countryCodes: []
};

const mutations: MutationTree<CountryCodesState> = {
  setCodesList(state, payload) {
    state.countryCodes = payload;
  }
};
const actions: ActionTree<CountryCodesState, RootState> = {
  async getCountryCodesList({ commit }) {
    const data = await Services.countryCodesList();
    commit("setCodesList", data);
    return data;
  }
};

const getters: GetterTree<CountryCodesState, RootState> = {
  countryCodesList(state: CountryCodesState): PhoneCodesListItem[] {
    return state.countryCodes;
  }
};

const countryCodes: Module<CountryCodesState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};

export default countryCodes;
