
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TitleHeader extends Vue {
  @Prop({ default: "" })
  icon!: string;

  @Prop({ default: "" })
  header!: string;
}
