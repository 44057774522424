import { CoursesWithPagination, InstructorsWithPagination } from "@/api/types";

import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";
function makeQueryParamsFromFilter({
  name = "",
  email = "",
  active = "all"
}: {
  name?: string;
  email?: string;
  active?: string | number;
}): string {
  const queryParamsArray = [];
  if (name.length > 0) {
    queryParamsArray.push(`users[name]=${name}`);
  }
  if (email.length > 0) {
    queryParamsArray.push(`users[email]=${email}`);
  }
  queryParamsArray.push(`active=${active}`);

  return queryParamsArray.join("&");
}

export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    limit: number,
    filters: { name?: string; email?: string; active?: string | number } = {
      name: "",
      email: "",
      active: "all"
    }
  ): Promise<InstructorsWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      let response = null;
      if (queryParams.length > 0) {
        response = (
          await $axios.get(
            `/api/instructors/?page=${page}&limit=${limit}&${queryParams}`
          )
        ).data;
      } else {
        response = (
          await $axios.get(`/api/instructors/?page=${page}&limit=${limit}`)
        ).data;
      }
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listWithoutPagination(): Promise<any> {
    try {
      return (await $axios.get(`/api/instructors/get-all-instructors`)).data
        .data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listByCourseID(
    id: number,
    active: string | number = "all"
  ): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/instructors/get-all-instructors?courses[id]=${id}&active=${active}`
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listByAuditID(
    id: number,
    active: string | number = "all"
  ): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/instructors/get-all-instructors?audits[id]=${id}&active=${active}`
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listByOnlineCourseID(
    id: number,
    active: string | number = "all"
  ): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/instructors/get-all-instructors?online_courses[id]=${id}&active=${active}`
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async info(id: string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/instructors/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async courses(id: string, page: number): Promise<CoursesWithPagination> {
    try {
      const response = (
        await $axios.get(`/api/instructors/${id}/courses/?page=${page}`)
      ).data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async servicesAdnGenders() {
    try {
      return (await $axios.get(`/api/services-list`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/instructors`, data)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(id: string, data: any): Promise<any> {
    try {
      const response = (await $axios.put(`/api/instructors/${id}`, data)).data
        .data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(instructorId: string) {
    try {
      return (await $axios.delete(`/api/instructors/${instructorId}`)).data
        .data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
