import {
  CalendarEvent,
  CourseEvent,
  CourseProgressWithPagination,
  DelegatesWithPagination
} from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";
function makeData(
  data: CourseEvent,
  delegates: Array<{ id: number | string; companyId: number | string }>
) {
  const tmpData = JSON.parse(JSON.stringify({ ...data }));
  tmpData.delegates = {};
  delegates.forEach(
    (delegate: { id: number | string; companyId: number | string }) => {
      tmpData.delegates[delegate.id] = delegate.companyId;
    }
  );
  return toSnake(tmpData);
}
export default ($axios: AxiosStatic) => ({
  async info(id: string): Promise<CourseEvent> {
    try {
      const response = (await $axios.get(`/api/events/${id}`)).data.data;
      // const { course, instructor, ...event } = response;
      // console.log("ev: ", event);

      // event.courseId = course.id;
      response.instructorId = response.instructor.id;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async stats(id: string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/events/${id}/stats`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async downloadTrainingRegister(
    eventId: number | string,
    companyId: number | string | null
  ): Promise<any> {
    try {
      return (
        await $axios.request({
          url: `/api/events/${eventId}/trading-register?company_id=${companyId}`,
          method: "GET",
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async infoAny(id: string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/events/${id}`)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async getLiftingMachine(eventId: number | string): Promise<any> {
    try {
      const response = (
        await $axios.get(`/api/events/${eventId}/lifting-equipment`)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async updateLiftingMachine(
    eventId: number | string,
    data: any
  ): Promise<any> {
    try {
      const response = (
        await $axios.put(
          `/api/events/${eventId}/lifting-equipment`,
          toSnake(data)
        )
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createLiftingMachine(
    eventId: number | string,
    data: any
  ): Promise<any> {
    try {
      const response = (
        await $axios.post(
          `/api/events/${eventId}/lifting-equipment`,
          toSnake(data)
        )
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async setGrade(eventId: string, data: any) {
    try {
      const response = (
        await $axios.post(`/api/events/${eventId}/grading`, data)
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async delegates(
    id: string,
    filters: { company: number | string | null; name: string } = {
      company: null,
      name: ""
    },
    page: number
  ): Promise<DelegatesWithPagination> {
    try {
      let paramsStr = "";
      if (filters.company !== null) {
        paramsStr = `delegates[company_id]=${filters.company}`;
      }
      if (filters.name !== "") {
        paramsStr =
          paramsStr.length === 0
            ? paramsStr + `&delegates[name]=${filters.name}`
            : `delegates[name]=${filters.name}`;
      }
      let res = null;
      if (Object.keys(filters).length === 0) {
        res = (await $axios.get(`/api/delegates?events[id]=${id}&page=${page}`))
          .data;
      } else {
        res = (
          await $axios.get(
            `/api/delegates?events[id]=${id}&page=${page}&${paramsStr}`
          )
        ).data;
      }
      return toCamel(res);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async allDelagates(
    id: string,
    filters: { company: number | string | null; name: string }
  ): Promise<DelegatesWithPagination> {
    try {
      let paramsStr = "";
      if (filters.company !== null) {
        paramsStr = `delegates[company_id]=${filters.company}`;
      }
      if (filters.name !== "") {
        paramsStr =
          paramsStr.length === 0
            ? paramsStr + `&delegates[name]=${filters.name}`
            : `delegates[name]=${filters.name}`;
      }
      let res = null;
      if (Object.keys(filters).length === 0) {
        res = (await $axios.get(`/api/delegates/no_paginator?events[id]=${id}`))
          .data;
      } else {
        res = (
          await $axios.get(
            `/api/delegates/no_paginator?events[id]=${id}&${paramsStr}`
          )
        ).data;
      }
      return toCamel(res);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async officeTypes(): Promise<any> {
    try {
      const response = (await $axios.get(`/api/event-office-types`)).data.data;
      return response.map((item: any) => toCamel(item));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getOfficeAddress() {
    try {
      return (await $axios.get(`/api/office_address`)).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async setAttend(
    eventId: string | number,
    data: { delegateId?: number | string; passport?: string; signature: string }
  ): Promise<any> {
    try {
      const reqData = toSnake(data);
      return (await $axios.post(`/api/events/${eventId}/attend`, reqData)).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async create(
    data: CourseEvent,
    delegates: Array<{ id: number | string; companyId: number | string }>
  ): Promise<any> {
    try {
      const reqData = makeData(data, delegates);
      const response = (await $axios.post(`/api/events/`, reqData)).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async update(
    data: CourseEvent,
    delegates: Array<{ id: number | string; companyId: number | string }>
  ): Promise<any> {
    try {
      const reqData = makeData(data, delegates);
      const response = (await $axios.put(`/api/events/${data.id}`, reqData))
        .data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(id: string | number) {
    try {
      return (await $axios.delete(`/api/events/${id}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async calendarList(
    filters: {
      startDate: string;
      endDate: string;
      course: string;
      audit: string;
      onlineCourse: string;
      company: string;
      instructor: string;
      premise: string;
    } = {
      startDate: "",
      endDate: "",
      course: "",
      audit: "",
      onlineCourse: "",
      company: "",
      instructor: "",
      premise: ""
    }
  ): Promise<Array<CalendarEvent>> {
    try {
      const queryParamsArray = ["limit=-1"];
      if (filters.startDate) {
        queryParamsArray.push(`between_dates[start_date]=${filters.startDate}`);
      }
      if (filters.endDate) {
        queryParamsArray.push(`between_dates[end_date]=${filters.endDate}`);
      }
      if (filters.course) {
        queryParamsArray.push(`courses[name]=${filters.course}`);
      }
      if (filters.audit) {
        queryParamsArray.push(`audits[id]=${filters.audit}`);
      }
      if (filters.onlineCourse) {
        queryParamsArray.push(`online_courses[id]=${filters.onlineCourse}`);
      }
      if (filters.company) {
        queryParamsArray.push(`companies[id]=${filters.company}`);
      }
      if (filters.instructor) {
        queryParamsArray.push(`events[instructor_id]=${filters.instructor}`);
      }
      if (filters.premise) {
        queryParamsArray.push(`events[office_type_id]=${filters.premise}`);
      }

      const query = queryParamsArray.join("&");

      if (query.length > 0) {
        return (await $axios.get(`/api/events?${query}`)).data.data;
      } else {
        return (await $axios.get(`/api/events`)).data.data;
      }
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async removeCompany(params: any): Promise<any> {
    try {
      return await $axios.patch(`/api/events/company_price/`, toSnake(params));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getEventTypes(): Promise<any> {
    try {
      const res = (await $axios.get(`/api/event-types`)).data.data;
      return res.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async rejectDelegateGrade(eventId: string, delegateId: string): Promise<any> {
    try {
      return (
        await $axios.post(`/api/events/${eventId}/reject-grade/${delegateId}`)
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async downloadOnlineCourseDocs(
    eventId: string,
    delegateId: string
  ): Promise<any> {
    try {
      const res = await $axios.get(
        `/api/events/${eventId}/archive/${delegateId}`,
        {
          responseType: "blob"
        }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async downloadOnlineCourseRecords(
    eventId: string,
    delegateId: string
  ): Promise<any> {
    try {
      const res = (
        await $axios.get(`/api/events/${eventId}/get-records/${delegateId}`)
      ).data.data;
      return res.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async downloadRecordById(id: string): Promise<any> {
    try {
      const res = await $axios.get(`/api/web-cam-records/${id}/download`, {
        responseType: "blob"
      });
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async downloadResultsPdf(eventId: string, delegateId: string): Promise<any> {
    try {
      const res = await $axios.get(
        `/api/events/${eventId}/pdf-result/${delegateId}`,
        {
          responseType: "blob"
        }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async downloadEssayPdf(eventId: string, delegateId: string): Promise<any> {
    try {
      const res = await $axios.get(
        `/api/events/${eventId}/essay/${delegateId}`,
        {
          responseType: "blob"
        }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async downloadFormativePdf(
    eventId: string,
    delegateId: string
  ): Promise<any> {
    try {
      const res = await $axios.get(
        `/api/events/${eventId}/formative/${delegateId}`,
        {
          responseType: "blob"
        }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async downloadPracticalPdf(
    eventId: string,
    delegateId: string
  ): Promise<any> {
    try {
      const res = await $axios.get(
        `/api/events/${eventId}/practical/${delegateId}`,
        {
          responseType: "blob"
        }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async uploadEssayPdf(
    eventId: string,
    delegateId: string,
    essay: Blob
  ): Promise<any> {
    try {
      const formData = new FormData();

      formData.append("essay", essay);

      const res = await $axios.post(
        `/api/events/${eventId}/essay/${delegateId}/upload-approved`,
        formData
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async uploadFormativePdf(
    eventId: string,
    delegateId: string,
    test: Blob
  ): Promise<any> {
    try {
      const formData = new FormData();

      formData.append("formative", test);

      const res = await $axios.post(
        `/api/events/${eventId}/formative/${delegateId}/upload-approved`,
        formData
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async uploadPracticalPdf(
    eventId: string,
    delegateId: string,
    test: Blob
  ): Promise<any> {
    try {
      const formData = new FormData();

      formData.append("practical", test);

      const res = await $axios.post(
        `/api/events/${eventId}/practical/${delegateId}/upload-approved`,
        formData
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async setEssayGrade(eventId: string, delegateId: string, grade: number) {
    try {
      const res = await $axios.post(
        `/api/events/${eventId}/essay/${delegateId}`,
        { grade }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async setFormativeGrade(eventId: string, delegateId: string, grade: number) {
    try {
      const res = await $axios.post(
        `/api/events/${eventId}/formative/${delegateId}`,
        { grade }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async setPracticalGrade(eventId: string, delegateId: string, grade: number) {
    try {
      const res = await $axios.post(
        `/api/events/${eventId}/practical/${delegateId}`,
        { grade }
      );
      return res.data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async approveDelegateGrade(
    eventId: string,
    delegateId: string
  ): Promise<any> {
    try {
      return (
        await $axios.post(`/api/events/${eventId}/approve-grade/${delegateId}`)
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async setNextEisaDateToDelegate(
    eventId: string,
    delegateId: string,
    data: Date
  ) {
    try {
      return (
        /* eslint-disable @typescript-eslint/camelcase */
        (
          await $axios.post(`/api/events/${eventId}/set-eisa/${delegateId}`, {
            next_eisa_date: data
          })
        ).data
      );
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getEventOfflineTrainingsLockedDates(eventId: string): Promise<any> {
    try {
      return (
        await $axios.get(
          `/api/events/${eventId}/offline-trainings/locked-dates`
        )
      ).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createEventOfflineTraining(data: any) {
    try {
      return (await $axios.post(`/api/events/offline-trainings`, data)).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteEventOfflineTraining(offlineTrainingId: number) {
    try {
      return (
        await $axios.delete(
          `/api/events/offline-trainings/${offlineTrainingId}`
        )
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async signEventOfflineTraining(offlineTrainingId: string, data: any) {
    try {
      return (
        await $axios.post(
          `/api/events/offline-trainings/${offlineTrainingId}/sign`,
          data
        )
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async downloadOfflineTrainingRegister(
    offlineTrainingId: string,
    delegateId: string
  ) {
    try {
      return (
        await $axios.get(
          `/api/events/offline-trainings/${offlineTrainingId}/delegates/${delegateId}/training-register`,
          {
            responseType: "blob"
          }
        )
      ).data;
    } catch (e) {
      const err = e as any;
      throw err;
    }
  },
  async courseProgressList(
    page: number,
    filters: {
      delegate: string;
      passportID: string;
      course: string | number;
    } = {
      delegate: "",
      passportID: "",
      course: ""
    },
    limit = 10
  ): Promise<CourseProgressWithPagination> {
    try {
      const queryParamsArray = [];
      if (filters.delegate) {
        queryParamsArray.push(`delegate_name=${filters.delegate}`);
      }
      if (filters.passportID) {
        queryParamsArray.push(`delegate_passport=${filters.passportID}`);
      }
      if (filters.course) {
        queryParamsArray.push(`course_name=${filters.course}`);
      }

      const query = queryParamsArray.join("&");

      let response = null;

      if (query.length) {
        response = (
          await $axios.get(
            `/api/events/occupational-certificate/progression?page=${page}&limit=${limit}&${query}`
          )
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/events/occupational-certificate/progression?page=${page}&limit=${limit}`
          )
        ).data;
      }
      return toCamel(response) as CourseProgressWithPagination;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
