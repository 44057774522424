import { CertificatesWithPagination } from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";
function makeQueryParamsFromFilter(filters: {
  course: number | null;
  company: number | null;
  delegate?: number | null;
  typeId?: number | null;
  passport?: number | string | null;
  certificate?: string | number | null;
  status?: number | null;
}): string {
  const queryParamsArray = [];
  if (filters.passport && filters.typeId === 1) {
    queryParamsArray.push(`internal[passport]=${filters.passport}`);
  }
  if (filters.typeId) {
    queryParamsArray.push(`certificates[type_id]=${filters.typeId}`);
  }
  if (
    filters.passport &&
    filters.typeId !== null &&
    filters.typeId !== undefined &&
    filters.typeId > 1
  ) {
    queryParamsArray.push(`manual[passport]=${filters.passport}`);
  }
  if (filters.delegate) {
    queryParamsArray.push(`internal[delegate]=${filters.delegate}`);
  }
  if (filters.course) {
    queryParamsArray.push(`certificates[certificable_id]=${filters.course}`);
  }
  if (filters.company) {
    queryParamsArray.push(`certificates[company_id]=${filters.company}`);
  }
  if (filters.certificate) {
    queryParamsArray.push(`internal[certificate]=${filters.certificate}`);
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status >= 0
  ) {
    queryParamsArray.push(`certificates[status]=${filters.status}`);
  }

  return queryParamsArray.join("&");
}

export default ($axios: AxiosStatic) => ({
  async list(
    page: number,
    limit: number,
    filters: {
      course: number | null;
      company: number | null;
      delegate: number | null;
      passport: string | null;
    } = {
      course: null,
      company: null,
      delegate: null,
      passport: null
    }
  ): Promise<CertificatesWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      const response = (
        await $axios.get(
          `/api/certificates/?page=${page}&limit=${limit}&${queryParams}`
        )
      ).data;

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listPaid(
    page: number,
    limit: number,
    filters: {
      course: number | null;
      company: number | null;
      delegate: number | null;
      passport: string | null;
    } = { course: null, company: null, delegate: null, passport: null }
  ): Promise<CertificatesWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      const response = (
        await $axios.get(
          `/api/certificates/?page=${page}&limit=${limit}&${queryParams}`
        )
      ).data;

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async listByDelegate(
    delegateId: number | string,
    page: number,
    filters: { course: number | null; company: number | null } = {
      course: null,
      company: null
    }
  ): Promise<CertificatesWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      let response = null;
      if (queryParams.length > 0) {
        response = (
          await $axios.get(
            `/api/certificates/?certificates[delegate_id]=${delegateId}&certificates[status]=0,1&page=${page}&${queryParams}`
          )
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/certificates/?certificates[delegate_id]=${delegateId}&certificates[status]=0,1&page=${page}`
          )
        ).data;
      }
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async listPending(
    page: number,
    limit: number,
    filters: {
      course: number | null;
      company: number | null;
      delegate: number | null;
      passport: string | null;
    } = { course: null, company: null, delegate: null, passport: null }
  ): Promise<CertificatesWithPagination> {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      const response = (
        await $axios.get(
          `/api/certificates/?page=${page}&limit=${limit}&${queryParams}`
        )
      ).data;

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async setPaid(id: number): Promise<any> {
    try {
      return (await $axios.patch(`/api/certificates/${id}/set-paid`)).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async download(id: number): Promise<any> {
    try {
      return (
        await $axios.request({
          url: `/api/certificates/${id}`,
          method: "GET",
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async certificateInfo(id: number): Promise<any> {
    try {
      return toCamel(
        (
          await $axios.request({
            url: `/api/certificates/show/${id}`,
            method: "GET"
          })
        ).data
      );
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async remove(id: string | number) {
    try {
      return (await $axios.delete(`/api/certificates/${id}`)).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async DownloadCertificate(id: string | number) {
    try {
      return (
        await $axios.request({
          url: `/qr/${id}`,
          method: "GET",
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createCertificateManually(data: object) {
    try {
      return await $axios.post("/api/certificates/", toSnake(data));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async updateCertificateManually(id: string, data: object) {
    try {
      return await $axios.put(`/api/certificates/${id}`, toSnake(data));
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },

  async getEditCertificate(
    page: number,
    limit: number,
    filters: {
      course: number | null;
      company: number | null;
      delegate: number | null;
      passport: string | null;
    } = { course: null, company: null, delegate: null, passport: null }
  ) {
    try {
      const queryParams = makeQueryParamsFromFilter(filters);
      const res = await $axios.get(
        `/api/certificates/?page=${page}&limit=${limit}&certificates[edited]=1&${queryParams}`
      );
      return toCamel(res.data);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async UpdInternalCertificate(data: any, id: number) {
    try {
      return await $axios.put(
        `/api/certificates/updateInternal/${id}`,
        toSnake(data)
      );
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getInternalById(id: number) {
    try {
      const { data } = await $axios.get(
        `/api/certificates/showInfoInternal/${id}`
      );
      return toCamel(data);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async downloadCertificates(certificates: any) {
    try {
      return (
        await $axios.post("/api/certificates/download/zip", certificates, {
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err);
    }
  }
});
