import { NavigationGuardNext } from "vue-router";
import { Store } from "vuex";

export default function guest({
  next,
  store
}: {
  next: NavigationGuardNext<Vue>;
  store: Store<any>;
}): void {
  if (store.getters["auth/loggedIn"]) {
    return next({
      name: "CourseEvents"
    });
  }

  return next();
}
