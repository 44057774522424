import _Audits from "@/api/audits";
import _Auth from "@/api/auth";
import _Certificates from "@/api/certificates";
import _Chat from "@/api/chat";
import _Companies from "@/api/companies";
import _CompanyInfo from "@/api/companyInfo";
import _CompanyRepresentative from "@/api/companyRepresentative";
import _Courses from "@/api/courses";
import _Delegates from "@/api/delegates";
import _Events from "@/api/events";
import _Goals from "@/api/goals";
import _Instructors from "@/api/instructors";
import _Meetings from "@/api/meetings";
import _OnlineCourses from "@/api/onlineCourses";
import _Privacy from "@/api/privacy";
import _Quotes from "@/api/quotes";
import _Roles from "@/api/roles";
import _Services from "@/api/service";
import _Statistics from "@/api/statistics";
import _Users from "@/api/users";
import _Zoom from "@/api/zoom";
import store from "@/store";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from "js-cookie";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const token = Cookies.get("access_token");
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

// Set token when defined
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
// const refreshAuthLogic = (failedRequest: any) =>
//   axios.post("/api/refresh").then(async (tokenRefreshResponse: any) => {
//     store.commit("auth/setAccessToken", tokenRefreshResponse.data.access_token);
//     Cookies.set("access_token", tokenRefreshResponse.data.token, {
//       expires: 365
//     });
//     failedRequest.response.config.headers["Authorization"] =
//       "Bearer " + tokenRefreshResponse.data.access_token;
//     return Promise.resolve();
//   });
axios.interceptors.request.use(function(config) {
  const token = Cookies.get("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 404) {
//       router.push({ name: "404" });
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );
createAuthRefreshInterceptor(axios, async () => {
  Cookies.remove("access_token");
  store.commit("auth/setAccessToken", "");
  store.commit("auth/setUser", null);
  window.location.href = "/login";
});

export const Auth = _Auth(axios);
export const Roles = _Roles(axios);
export const Users = _Users(axios);
export const Events = _Events(axios);
export const Instructors = _Instructors(axios);
export const Courses = _Courses(axios);
export const OnlineCourses = _OnlineCourses(axios);
export const Audits = _Audits(axios);
export const Delegates = _Delegates(axios);
export const Companies = _Companies(axios);
export const CompanyRepresentative = _CompanyRepresentative(axios);
export const Certificates = _Certificates(axios);
export const Statistics = _Statistics(axios);
export const Privacy = _Privacy(axios);
export const Quotes = _Quotes(axios);
export const Goals = _Goals(axios);
export const CompanyInfoCurrent = _CompanyInfo(axios);
export const Chat = _Chat(axios);
export const Zoom = _Zoom(axios);
export const Meetings = _Meetings(axios);
export const Services = _Services(axios);
