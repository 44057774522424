import { LoginResponse } from "@/api/types";
import { AxiosStatic } from "axios";

export default ($axios: AxiosStatic) => ({
  async forgotPassword(email: string) {
    try {
      return (await $axios.post("/forgot", { email })).data.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async login(email: string, password: string): Promise<LoginResponse> {
    return (await $axios.post("/login", { email, password })).data.data;
  },
  logout(email: string) {
    return $axios.post("/api/logout", { email });
  },
  async changePassword(
    token: string,
    email: string,
    password: string,
    confirmPassword: string
  ) {
    return (
      await $axios.post("/change", {
        email,
        token,
        password,
        // eslint-disable-next-line @typescript-eslint/camelcase
        password_confirmation: confirmPassword
      })
    ).data.data;
  }
});
