
// import Auth from "@/layouts/auth.vue";
// import Default from "@/layouts/default.vue";
// import Public from "@/layouts/public.vue";
import Vue from "vue";

export default Vue.extend({
  name: "App",

  components: {
    // Default,
    // Public,
    // Auth
  },
  computed: {
    layout() {
      if (this.$route?.meta?.layout) {
        // return this.$route.meta.layout;
        // return this.$route.meta.layout;
        return () => import(`./layouts/${this.$route.meta?.layout}.vue`);
      }
      // return "Default"
      return () => import("@/layouts/default.vue");
    }
  }
});
