
import { Events } from "@/api";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

const namespace = "auth";

@Component
export default class TooltipTemplate extends Vue {
  @Prop({ required: true, default: () => ({}) })
  scheduler!: any;
  @Prop({ required: true, default: () => true })
  isAgendaView!: boolean;
  @Prop({ required: true, default: () => ({}) })
  templateTooltipModel!: any;

  @Getter("getUser", { namespace })
  getUser!: object | null;

  menu = false;
  $vuetify: any;

  get eventBackgroundColor() {
    let color = "";
    if (this.isFailed) {
      color = "#fa8072"; // failed courses color
    } else if (this.isAudit) {
      color = "orange"; // audit color
    } else if (this.isOnlineCourse) {
      color = "#800080"; // online courses color
    }
    return color ? { backgroundColor: color } : {};
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }
  get canDeleteEvent() {
    const currentUser: any = this.getUser;
    const permissionSearch = currentUser.permissions.find(
      (userPermission: string) => userPermission === "delete_event"
    );
    return Boolean(permissionSearch);
  }

  get event() {
    return this.templateTooltipModel.appointmentData;
  }
  get eventStartTime() {
    const eventDate = new Date(this.event.startDate);
    return moment(eventDate).format("HH:mm");
  }
  get eventEndTime() {
    const eventDate = new Date(this.event.endDate);
    return moment(eventDate).format("HH:mm");
  }
  get isAudit() {
    return this.templateTooltipModel.appointmentData.event_type_id == 2;
  }
  get isFailed() {
    return this.templateTooltipModel.appointmentData.delegates_event_failure;
  }
  get isOnlineCourse() {
    return this.templateTooltipModel.appointmentData.event_type_id == 3;
  }
  get blockDeletion() {
    return this.event?.block_deletion || false;
  }
  get currentDate() {
    return this.templateTooltipModel?.targetedAppointmentData?.startDate;
  }
  get hasOfflineQualificationTraining() {
    if (!this.currentDate || !this.event?.offline_trainings?.length)
      return false;
    const inRange = (this.event?.offline_trainings || []).some(
      (session: any) => {
        if (
          moment(this.currentDate).isSameOrAfter(
            moment(session.started_at).startOf("day")
          ) &&
          moment(this.currentDate).isSameOrBefore(moment(session.finished_at))
        ) {
          return true;
        }
        return false;
      }
    );
    return inRange;
  }

  async onEdit() {
    await this.$router.push({
      name: "EditEvent",
      params: { id: this.event.id.toString() }
    });
  }
  async onGrade() {
    await this.$router.push({
      name: "ViewEvent",
      params: { id: this.event.id.toString() }
    });
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      await Events.remove(this.event.id);
      this.$emit("delete", this.event.text);
    }
  }
}
