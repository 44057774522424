import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async getScheduleCalls(eventId: string): Promise<any> {
    try {
      const response = (await $axios.get(`/api/meetings?events[id]=${eventId}`))
        .data.data;
      return response.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async createCall(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/meetings`, toSnake(data))).data;

      return response;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async deleteCall(callId: number): Promise<any> {
    try {
      const response = (await $axios.delete(`/api/meetings/${callId}`)).data
        .data;

      return response;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
