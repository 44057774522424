import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";
// import moment from "moment";

export default ($axios: AxiosStatic) => ({
  async createGoal(data: any): Promise<any> {
    try {
      const response = (await $axios.post(`/api/goals/`, toSnake(data))).data
        .data;
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async updateGoal(data: any): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/goals/${data.userId}`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async deleteAllGoals(data: any): Promise<any> {
    try {
      await $axios.delete(`/api/goals/delete/${data.userId}`, {
        data: toSnake(data)
      });
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async getUserGoalsData(id: number | string | undefined) {
    try {
      const response = (await $axios.get(`/api/goals/${id}`)).data;
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async getTotalGoalsUser(id: number | undefined | string) {
    try {
      const response = (await $axios.get(`api/goals?total[user_id]=${id}`))
        .data;
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async getGoalsData() {
    try {
      const response = (await $axios.get(`/api/goals/`)).data;
      return toCamel(response);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async deleteSingleGoal(id: number | string) {
    try {
      return await $axios.delete(`/api/goals/${id}`);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async getRatingCompany() {
    try {
      return (await $axios.get("api/users/rating")).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  }
});
