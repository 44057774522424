
import TitleButtons from "@/components/common/TitleButtons.vue";
import TitleHeader from "@/components/common/TitleHeader.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { TitleHeader, TitleButtons }
})
export default class PageTitle extends Vue {
  @Prop({ required: false })
  buttonsArray!: [
    {
      text: string;
      icon: string;
      color: string;
      action: string;
      activeClass?: string;
      disabled?: boolean;
    }
  ];

  @Prop({ default: false, required: false })
  disabled!: boolean;

  @Prop({
    default: () => [
      {
        icon: "",
        header: ""
      }
    ]
  })
  headersTitle!: [
    {
      icon: string;
      header: string;
    }
  ];

  @Prop({ default: false })
  isHideBorderBottom!: boolean;

  emitHandler(actionName: string) {
    if (actionName) {
      this.$emit(`${actionName}`);
    }
  }
}
