import { Instructors } from "@/api";
import { InstructorsState, RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const state: InstructorsState = {
  instructors: []
};

const mutations: MutationTree<InstructorsState> = {
  setInstructors(state, payload) {
    state.instructors = payload;
  }
};

const actions: ActionTree<InstructorsState, RootState> = {
  async getInstructorslistByCourseID(
    { commit },
    { id, active }: { id: number; active: number | string }
  ) {
    try {
      const data = await Instructors.listByCourseID(id, active);
      commit("setInstructors", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getInstructorslistByAuditID(
    { commit },
    { id, active }: { id: number; active: number | string }
  ) {
    try {
      const data = await Instructors.listByAuditID(id, active);
      commit("setInstructors", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getInstructorslistByOnlineCourseID(
    { commit },
    { id, active }: { id: number; active: number | string }
  ) {
    try {
      const data = await Instructors.listByOnlineCourseID(id, active);
      commit("setInstructors", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  },
  async getInstructorsListWithoutPagination({ commit }) {
    try {
      const data = await Instructors.listWithoutPagination();
      commit("setInstructors", data);
      return data;
    } catch (e) {
      const err = e as any;
      throw err.errors;
    }
  }
};

const getters: GetterTree<InstructorsState, RootState> = {};

const instructors: Module<InstructorsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default instructors;
