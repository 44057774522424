import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";
import { CreateCRType } from "./types";

export default ($axios: AxiosStatic) => ({
  async createCR(data: CreateCRType): Promise<any> {
    try {
      const response = (
        await $axios.post(`/api/company-representatives`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async editCR(data: CreateCRType, crId: string): Promise<any> {
    try {
      const response = (
        await $axios.put(`/api/company-representatives/${crId}`, toSnake(data))
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async removeCR(crId: string) {
    try {
      return (await $axios.delete(`/api/company-representatives/${crId}`)).data
        .data;
    } catch (e) {
      const err = e as any;

      throw toCamel(err.response);
    }
  },
  async getVaultEmailStatus(crId: string): Promise<any> {
    try {
      const response = (
        await $axios.get(
          `api/company-representatives/${crId}/vault-email-status`
        )
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async resendVaultVerificationEmail(crId: string): Promise<any> {
    try {
      const response = (
        await $axios.post(
          `api/company-representatives/${crId}/vault-resend-verification`
        )
      ).data.data;
      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw err.response.data;
    }
  },
  async list(
    page: number,
    limit: number,
    filters: { name: string; email: string } = { name: "", email: "" }
  ): Promise<any> {
    try {
      const queryParamsArray = [];
      if (filters.name) {
        queryParamsArray.push(`company_representatives[name]=${filters.name}`);
      }
      if (filters.email) {
        queryParamsArray.push(`cr[email]=${filters.email}`);
      }
      const query = queryParamsArray.join("&");
      let response = null;

      if (query) {
        response = (
          await $axios.get(
            `/api/company-representatives/?page=${page}&limit=${limit}&${query}`
          )
        ).data;
      } else {
        response = (
          await $axios.get(
            `/api/company-representatives/?page=${page}&limit=${limit}`
          )
        ).data;
      }

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
