import { AxiosStatic } from "axios";
import { toCamel, toSnake } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async accept(id: number | string | undefined, params: any) {
    try {
      const response = await $axios.post(
        `public/companies/delegates/${id}`,
        toSnake(params)
      );
      return toCamel(response.data.data);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async decline(id: number | undefined) {
    try {
      const response = await $axios.post(`public/quotes/decline/${id}`);
      return toCamel(response.data.data);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },

  async getQuoteData(id: number) {
    try {
      const response = await $axios.get(`public/quote/${id}`);
      return toCamel(response.data.data);
    } catch (e) {
      throw toCamel(e.response.data);
    }
  }
});
