import { AxiosStatic } from "axios";
import { Role } from "@/api/types";
import { toCamel } from "snake-camel";

export default ($axios: AxiosStatic) => ({
  async list(): Promise<Array<Role>> {
    try {
      return (await $axios.get("/api/roles")).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async create(name: string, permissions: Array<number>) {
    try {
      return (await $axios.post("/api/roles", { name, permissions })).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async info(roleId: string) {
    try {
      return (await $axios.get(`/api/roles/${roleId}`)).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async update(
    roleId: string,
    role: { name: string; permissions: Array<number> }
  ) {
    try {
      return (await $axios.put(`/api/roles/${roleId}`, role)).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async remove(roleId: string) {
    try {
      return (await $axios.delete(`/api/roles/${roleId}`)).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  },
  async permissions() {
    try {
      return (await $axios.get("/api/permissions")).data.data;
    } catch (e) {
      throw toCamel(e.response.data);
    }
  }
});
