
import {
  Audits,
  Companies,
  Courses,
  Events,
  Instructors,
  OnlineCourses
} from "@/api";
import { CalendarEvent } from "@/api/types";
import TitleHeader from "@/components/common/TitleHeader.vue";
import AgendaTemplate from "@/components/scheduler/AgendaTemplate.vue";
import TooltipTemplate from "@/components/scheduler/TooltipTemplate.vue";
import { DxResource, DxScheduler } from "devextreme-vue/scheduler";
import { Component, Vue, Watch } from "vue-property-decorator";

import PageTitle from "@/components/common/PageTitle.vue";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import { namespace } from "vuex-class";

const EventsStore = namespace("events");

interface Filter {
  course: string;
  audit: string;
  onlineCourse: string;
  company: string;
  instructor: string;
  premise: string;
}

@Component({
  components: {
    TitleHeader,
    TooltipTemplate,
    AgendaTemplate,
    DxScheduler,
    DxResource,
    PageTitle
  }
})
export default class EventsListPage extends Vue {
  timeZoneOffset = moment().utcOffset();

  key = 1;
  buttonsArray = [
    {
      text: "Create",
      icon: "",
      action: "create"
    }
  ];
  loading = false;
  scheduler: any = null;
  agendaDuration = 7;
  // maxEventDuration = 5;
  views = ["agenda", "month"];
  currentDate = moment().toISOString();
  selectedDate = this.currentDate;
  dataSource: Array<CalendarEvent> = [];
  priorityData = [
    {
      id: true,
      color: "#009688"
    },
    {
      id: false,
      color: "#2061c3"
    }
  ];

  editing = {
    allowAdding: false,
    allowDeleting: false,
    allowUpdating: false,
    allowResizing: false,
    allowDragging: false
  };

  courses = [];
  onlineCourses = [];
  audits = [];
  companies = [];
  instructors = [];
  premises = [];
  filters: Filter = {
    course: "",
    audit: "",
    onlineCourse: "",
    company: "",
    instructor: "",
    premise: ""
  };

  @EventsStore.Action("getCoursesDurations")
  private getCoursesDurations!: () => Promise<any>;

  get isAgendaView() {
    return this.scheduler?._currentView === "agenda";
  }
  get isMonthView() {
    return this.scheduler?._currentView === "month";
  }
  get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  async mounted() {
    // const res = await this.getCoursesDurations().catch(() => {
    //   //
    // });

    // this.maxEventDuration = res.reduce((acc: number, cur: any) => {
    //   if (cur.day > acc) return (acc = cur.day);
    //   else return acc;
    // }, 0);

    [
      this.courses,
      this.audits,
      this.onlineCourses,
      this.companies,
      this.premises,
      this.instructors
    ] = await Promise.all([
      await Courses.listWithoutPagination(),
      await Audits.listWithoutPagination(),
      await OnlineCourses.listWithoutPagination(),
      await Companies.listWithoutPagination(),
      await Events.officeTypes(),
      await Instructors.listWithoutPagination()
    ]);
  }

  @Watch("filters", { deep: true })
  filtersHandler() {
    this.key++;
  }

  async loadEvents(start: string, end: string) {
    this.loading = true;
    const datefilter = this.setDataFilter(start, end);
    try {
      const events = await Events.calendarList({
        ...datefilter,
        ...this.filters
      });

      events.forEach(dataItem => {
        dataItem.startDate = moment(dataItem.startDate)
          .subtract(this.timeZoneOffset, "minute")
          .toISOString();
        dataItem.endDate = moment(dataItem.endDate)
          .subtract(this.timeZoneOffset, "minute")
          .toISOString();
      });

      this.dataSource = [...events];
      return events;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.loading = false;
    }
  }

  apiEvents = new CustomStore({
    key: "ID",
    load: async (options: any) => {
      const start = options.filter[0][0][0][2];
      const end = options.filter[0][0][1][2];

      return await this.loadEvents(start, end);
    }
  });

  setDataFilter(start: string, end: string) {
    const startDate = moment(start)
      .add(this.timeZoneOffset, "minute")
      .toISOString();
    const endDate = moment(end)
      .add(this.timeZoneOffset, "minute")
      .toISOString();

    return {
      startDate,
      endDate
    };
  }

  async onCreate() {
    await this.$router.push({ name: "CourseEventsCreate" });
  }
  async onDelete(eventName: string) {
    try {
      // this.dataSource = await Events.calendarList();
      this.$dialog.notify.success(
        `Success! Event "${eventName}" has been deleted!`,
        {
          position: "bottom-right",
          timeout: 5000
        }
      );
      this.key++;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  onAppointmentClick(e: any) {
    e.cancel = this.isMonthView ? false : true;
  }

  async onAppointmentDblClick(e: any) {
    e.event.preventDefault();
    await this.$router.push({
      name: "EditEvent",
      params: { id: e.targetedAppointmentData.id.toString() }
    });
  }
  onContentReady(e: any) {
    this.scheduler = e.component;
  }
  createAppointment(e: any) {
    e.event.preventDefault();
    const selectedDate = moment(this.currentDate).diff(
      e.cellData.startDate,
      "hours"
    );
    if (selectedDate <= 0) {
      this.$router.push({
        name: "CourseEventsCreate",
        params: { date: e.cellData.startDate }
      });
    }
  }
}
