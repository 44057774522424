import { EarningFilter } from "@/api/types";
import { AxiosStatic } from "axios";
import { toCamel } from "snake-camel";

function snakeCase(str: string): string {
  return str
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join("_");
}
function filtersToQueryParams(filter: EarningFilter): string {
  const queryParamsArray: Array<string> = [];
  const props = Object.keys(filter);
  for (const prop of props) {
    if (filter[prop as keyof EarningFilter] !== "") {
      if (prop === "auditName") {
        queryParamsArray.push(
          `audits[id]=${filter[prop as keyof EarningFilter]}`
        );
      } else if (prop === "onlineCourseName") {
        queryParamsArray.push(
          `online_courses[id]=${filter[prop as keyof EarningFilter]}`
        );
      } else {
        queryParamsArray.push(
          `${snakeCase(prop)}=${filter[prop as keyof EarningFilter]}`
        );
      }
    }
  }
  let queryParams = "";
  if (queryParamsArray.length > 0) {
    queryParams = `?${queryParamsArray.join("&")}`;
  }
  return queryParams;
}
export default ($axios: AxiosStatic) => ({
  async getInstructorsDailyStatistics(): Promise<any> {
    try {
      const response = (await $axios.get(`api/reports/instructors`)).data.data;
      return response.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getAllStat(filter: number | null): Promise<any> {
    try {
      let response = null;
      if (filter) {
        response = (
          await $axios.get(`/api/reports/board/?filter_key=${filter}`)
        ).data.data;
      } else {
        response = (await $axios.get(`/api/reports/board`)).data.data;
      }

      return toCamel(response);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getEarningsData(filter: EarningFilter): Promise<any> {
    try {
      const queryParams = filtersToQueryParams(filter);
      const response = (await $axios.get(`/api/earnings/${queryParams}`)).data
        .data;
      return response.map(toCamel).map((item: any) => {
        const dateArray = item.date.split("-");
        const date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
        return { ...item, date };
      });
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async exportExcel(filter: EarningFilter): Promise<any> {
    try {
      const queryParams = filtersToQueryParams(filter);
      return (
        await $axios.request({
          url: `/api/earnings/export/${queryParams}`,
          method: "GET",
          responseType: "blob"
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getBoardFilters(): Promise<any> {
    try {
      return (await $axios.get("/api/reports/filters")).data.data;
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  },
  async getStatsCCAgent(filter: number | null): Promise<any> {
    try {
      const response = (
        await $axios.get(`/api/reports/cc-agent?filter_key=${filter}`)
      ).data.data;
      return response.map(toCamel);
    } catch (e) {
      const err = e as any;
      throw toCamel(err.response.data);
    }
  }
});
