import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store";
import EventsListPage from "@/views/events/EventsListPage.vue";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/LoginPage.vue"),
    meta: {
      title: "Login",
      layout: "auth",
      middleware: [guest]
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/auth/ForgotPasswordPage.vue"),
    meta: {
      title: "Forgot password",
      layout: "auth",
      middleware: [guest]
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/auth/ResetPasswordPage.vue"),
    meta: {
      title: "Reset password",
      layout: "auth",
      middleware: [guest]
    }
  },
  {
    path: "/training-course-quote/:id",
    name: "PrivacyFormPage",
    component: () => import("@/views/privacy-form/PrivacyFormPage.vue"),
    meta: {
      title: "Privacy Form",
      layout: "public"
    }
  },

  {
    path: "/instructors",
    name: "Instructors",
    component: () => import("@/views/instructors/InstructorsPage.vue"),
    meta: {
      title: "Instructors",
      middleware: [auth]
    }
  },
  {
    path: "/instructors/create",
    name: "CreateInstructor",
    component: () => import("@/views/instructors/CreateInstructorsPage.vue"),
    meta: {
      title: "Create instructor",
      middleware: [auth]
    }
  },
  {
    path: "/instructors/:id",
    name: "ViewInstructor",
    component: () => import("@/views/instructors/ViewInstructorsPage.vue"),
    meta: {
      title: "View Instructor",
      middleware: [auth]
    }
  },
  {
    path: "/instructors/:id/edit",
    name: "EditInstructor",
    component: () => import("@/views/instructors/EditInstructorsPage.vue"),
    meta: {
      title: "Edit Instructor",
      middleware: [auth]
    }
  },
  {
    path: "/setting/users",
    name: "Users",
    component: () => import("@/views/settings/UsersListPage.vue"),
    meta: {
      title: "Users",
      middleware: [auth]
    }
  },
  {
    path: "/setting/users/:id",
    name: "EditUser",
    component: () => import("@/views/settings/EditUserPage.vue"),
    meta: {
      title: "Edit User",
      middleware: [auth]
    }
  },
  {
    path: "/setting/users/create",
    name: "CreateUser",
    component: () => import("@/views/settings/CreateUserPage.vue"),
    meta: {
      title: "Create User",
      middleware: [auth]
    }
  },
  {
    path: "/setting/user-roles",
    name: "UserRoles",
    component: () => import("@/views/settings/UserRolesPage.vue"),
    meta: {
      title: "User roles",
      middleware: [auth]
    }
  },
  {
    path: "/setting/user-roles/create",
    name: "CreateRole",
    component: () => import("@/views/settings/CreateUserRolesPage.vue"),
    meta: {
      title: "Create role",
      middleware: [auth]
    }
  },
  {
    path: "/setting/user-roles/:id",
    name: "EditRole",
    component: () => import("@/views/settings/EditUserRolesPage.vue"),
    meta: {
      title: "Edit role",
      middleware: [auth]
    }
  },
  {
    path: "/certificate",
    name: "Certificate",
    component: () => import("@/views/certificate/CertificatePage.vue"),
    meta: {
      title: "Certificate",
      middleware: [auth]
    }
  },
  {
    path: "/certificate/edit",
    name: "CertificateEdit",
    component: () => import("@/views/certificate/CertificateEditPage.vue"),
    meta: {
      title: "Edit certificate",
      middleware: [auth]
    }
  },
  {
    path: "/companies",
    name: "CompaniesList",
    component: () => import("@/views/companies/CompaniesListPage.vue"),
    meta: {
      title: "Companies",
      middleware: [auth]
    }
  },
  {
    path: "/companies/create",
    name: "CompaniesCreate",
    component: () => import("@/views/companies/CompaniesCreatePage.vue"),
    meta: {
      title: "Create company",
      middleware: [auth]
    }
  },
  {
    path: "/companies/:id",
    name: "CompaniesView",
    component: () => import("@/views/companies/CompaniesViewPage.vue"),
    meta: {
      title: "Company view",
      middleware: [auth]
    }
  },
  {
    path: "/companies/:id/edit",
    name: "CompaniesEdit",
    component: () => import("@/views/companies/CompaniesEditPage.vue"),
    meta: {
      title: "Company edit",
      middleware: [auth]
    }
  },
  {
    path: "/courses",
    name: "CoursesList",
    component: () => import("@/views/courses/CoursesListPage.vue"),
    meta: {
      title: "Courses list",
      middleware: [auth]
    }
  },
  {
    path: "/courses/create",
    name: "CreateCourse",
    component: () => import("@/views/courses/CreateCoursePage.vue"),
    meta: {
      title: "Create Course",
      middleware: [auth]
    }
  },
  {
    path: "/courses/:id",
    name: "EditCourse",
    component: () => import("@/views/courses/EditCoursePage.vue"),
    meta: {
      title: "Edit course",
      middleware: [auth]
    }
  },
  {
    path: "/online-courses",
    name: "OnlineCoursesList",
    component: () => import("@/views/online-courses/OnlineCoursesList.vue"),
    meta: {
      title: "Online courses list",
      middleware: [auth]
    }
  },
  {
    path: "/online-courses/create",
    name: "CreateOnlineCourse",
    component: () => import("@/views/online-courses/CreateOnlineCourse.vue"),
    meta: {
      title: "Create online course",
      middleware: [auth]
    }
  },
  {
    path: "/online-courses/:id",
    name: "EditOnlineCourse",
    component: () => import("@/views/online-courses/EditOnlineCourse.vue"),
    meta: {
      title: "Edit course",
      middleware: [auth]
    }
  },
  {
    path: "/courses-progress",
    name: "CoursesProgress",
    component: () => import("@/views/online-courses/CourseProgression.vue"),
    meta: {
      title: "Course progress",
      middleware: [auth]
    }
  },
  {
    path: "/audits",
    name: "AuditsList",
    component: () => import("@/views/audit/AuditsList.vue"),
    meta: {
      title: "Audits list",
      middleware: [auth]
    }
  },
  {
    path: "/audits/create",
    name: "CreateAudit",
    component: () => import("@/views/audit/CreateAudit.vue"),
    meta: {
      title: "Create Audit",
      middleware: [auth]
    }
  },
  {
    path: "/audits/:id",
    name: "EditAudit",
    component: () => import("@/views/audit/EditAudit.vue"),
    meta: {
      title: "Edit audit",
      middleware: [auth]
    }
  },

  {
    path: "/delegates",
    name: "DelegatesList",
    component: () => import("@/views/delegates/DelegatesListPage.vue"),
    meta: {
      title: "Delegates",
      middleware: [auth]
    }
  },
  {
    path: "/delegates/create",
    name: "DelegatesCreate",
    component: () => import("@/views/delegates/DelegatesCreatePage.vue"),
    meta: {
      title: "Delegates create",
      middleware: [auth]
    }
  },
  {
    path: "/delegates/:id",
    name: "DelegatesView",
    component: () => import("@/views/delegates/DelegatesViewPage.vue"),
    meta: {
      title: "Delegates view",
      middleware: [auth]
    }
  },
  {
    path: "/delegates/:id/edit",
    name: "DelegatesEdit",
    component: () => import("@/views/delegates/DelegatesEditPage.vue"),
    meta: {
      title: "Delegates edit",
      middleware: [auth]
    }
  },

  {
    path: "/reports/board",
    name: "Reports",
    component: () => import("@/views/reports/ReportsPage.vue"),
    meta: {
      title: "Reports",
      middleware: [auth]
    }
  },
  {
    path: "/reports/earnings",
    name: "Earnings",
    component: () => import("@/views/reports/EarningPage.vue"),
    meta: {
      title: "Earnings",
      middleware: [auth]
    }
  },
  {
    path: "/qr/:id",
    name: "DownloadCertificate",
    component: () =>
      import("@/views/downloadCertificate/downloadCertificatePage.vue"),
    meta: {
      title: "Download Certificates",
      layout: "public"
    }
  },
  {
    path: "/signature",
    name: "Signature",
    component: () => import("@/views/signature/SignaturePage.vue"),
    meta: {
      title: "Signature",
      middleware: [auth]
    }
  },
  {
    path: "/event/create",
    name: "CourseEventsCreate",
    component: () => import("@/views/events/CreateEventPage.vue"),
    meta: {
      title: "Create Course Event",
      middleware: [auth]
    }
  },
  {
    path: "/event/:id/edit",
    name: "EditEvent",
    component: () => import("@/views/events/EditEventPage.vue"),
    meta: {
      title: "View course event",
      middleware: [auth]
    }
  },
  {
    path: "/event/:id",
    name: "ViewEvent",
    component: () => import("@/views/events/EventsViewPage.vue"),
    meta: {
      title: "View course event",
      middleware: [auth]
    }
  },
  {
    path: "/",
    name: "CourseEvents",
    component: EventsListPage,
    meta: {
      title: "Course events",
      middleware: [auth]
    }
  },
  {
    path: "/quotes",
    name: "Quotes",
    component: () => import("@/views/quotes/QuotesListPage.vue"),
    meta: {
      title: "Quotes",
      middleware: [auth]
    }
  },
  {
    path: "/targets",
    name: "Targets",
    component: () => import("@/views/goals/GoalsPage.vue"),
    meta: {
      title: "Targets",
      middleware: [auth]
    }
  },
  {
    path: "/company-info",
    name: "CompanyInfo",
    component: () => import("@/views/companyInfo/CompanyInfo.vue"),
    meta: {
      title: "Company Info",
      middleware: [auth]
    }
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("@/views/chat/ChatPage.vue"),
    meta: {
      title: "Chat",
      middleware: [auth]
    }
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/PageNotFound.vue"),
    meta: {
      title: "Page Not Found",
      middleware: [auth]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (!to?.meta?.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});
export default router;
